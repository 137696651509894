<template>
  <div>
    <h2>
      Index of Companies that Allow a Person to Download Their Information
    </h2>
    <table class="table">
      <thead>
        <tr>
          <th style="width: 5%">#</th>
          <th style="width: 15%">Name/Icon</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(app, i) in appsSortedByName" :key="i">
          <th scope="row">{{ i + 1 }}</th>
          <td>
            <img class="app-icon" :src="'/images/app_icons/' + app.icon" />
            <br />
            <a :href="app.url">{{ app.name }}</a>
          </td>
          <td>{{ app.desc }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import appsJSON from "@/assets/app_list.json";

export default {
  name: "AppList",
  data() {
    return {
      apps: appsJSON,
    };
  },
  computed: {
    appsSortedByName() {
      let sortFunction = (appA, appB) => {
        let nameA = appA.name.toUpperCase();
        let nameB = appB.name.toUpperCase();

        return nameA.localeCompare(nameB);
      };
      return this.apps.slice().sort(sortFunction);
    }
  }
};
</script>

<style lang="css" scoped>
.table {
  text-align: initial;
  width: 100%;
}

table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
    /* white-space:nowrap; */
}

.app-icon {
  width: auto;
  height: 120px;
}

a {
  text-decoration: none;
}
</style>
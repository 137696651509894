<template>
  <div class="home">
    <AppList />
  </div>
</template>

<script>
// @ is an alias to /src
import AppList from '@/components/AppList.vue';

export default {
  name: 'HomeView',
  components: {
    AppList
  }
}
</script>
